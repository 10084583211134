import React from 'react'

const LatestStudentSignUpTable = ({ headers, data,TbaleName }) => {
    return (
        <div className="">
            <h2 className="text-xl font-bold inter500 p-4 ">{TbaleName}</h2>
            <div className="overflow-x-auto">
                <table className="w-full ">
                    <thead className='bg-[#F1F5F9] text-left '>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} className="py-2.5 px-4 capitalize inter500 ">
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index} className="border-b">
                                <td className="px-4 py-2 flex items-center gap-2">
                                    <img
                                        src={row.avatar}
                                        alt={row.name}
                                        className="w-10 h-10 rounded-full"
                                    />
                                    <div>
                                        <p className="font-medium">{row.name}</p>
                                        <p className="text-sm text-gray-500">{row.email}</p>
                                    </div>
                                </td>
                                <td className="px-4 py-2 ">{row.role}</td>
                                <td className="px-4 py-2 ">{row.lastActivity}</td>
                                <td className="px-4 py-2  text-right">
                                    <button className="text-gray-500 hover:text-gray-700">⋮</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
              
            </div>
        </div>
    )
}

export default LatestStudentSignUpTable