import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./userSlice"
import  courseSlice from './courseSlice'
import  expertSlice from './expertSlice'

export const store = configureStore({
  reducer: {
    authSlice:authReducer,
    courseSlice:courseSlice,
    expertSlice:expertSlice
  },
})