// apiConfig.js
import axios from 'axios';
import { baseURL } from '../BaseURL';


const apiClient = axios.create({
  baseURL: baseURL,
});


// export function authHeader() {
//     let user = localStorage.getItem('accessToken');
//     console.log(user)

//     if (user) {
//         return { 'Authorization': 'Bearer ' + user}; // returning an object
//     } else {
//         return {}; // returning an empty object if no user or token
//     }
// }

// export const headerForPrivateAPI = new Headers({
//   'Content-Type': 'application/json',
//   'Authorization': authHeader().Authorization
// })

// Function to set headers
export const setHeaders = () => {
  let user = localStorage.getItem('accessToken');

  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user
    },
  };
};

export function logoutFunction() {
  localStorage.removeItem('accessToken');

  window.location.replace('/login');
  window.location.reload()
}




export const apiRequest = async (method, endpoint, data ) => {

  // console.log('0000000000000000!!!!!!!!', method, endpoint, data );

  let user = localStorage.getItem('accessToken');

  try {
    const response = await apiClient({
      method: method,
      url: endpoint,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user
      },
    });

    // console.log('response!!!!!!!!!!!!!!!!!!!!!!', response);

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: 'Unknown error occurred' };
  }
};
