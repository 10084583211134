import React from 'react'
import ReactApexChart from 'react-apexcharts';

const Chart = () => {
    const chartOptions = {
        series: [44, 55, 67],
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '18px',
                    },
                    value: {
                        fontSize: '16px',
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        formatter: function () {
                            return 249;
                        },
                    },
                },
            },
        },
        labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
    };
    return (
        <div>
            <div id="chart">
                <ReactApexChart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="radialBar"
                    height={300}
                />
            </div>
        </div>
    )
}

export default Chart