// courseSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../_helpers/apiConfig';

// Async thunk for fetching trending courses
export const fetchTrendingCourses = createAsyncThunk("courses/trendingCourses", async (filters) => {
  const response = await apiRequest('POST', '/v1/course/top-performing-courses', filters);
  // console.log("fjgfjshjfs",response)
  return response; 
});

export const fetchAllCourses = createAsyncThunk("course/courseListForAdmin", async (filters) => {
  const response = await apiRequest('POST', '/v1/course/courseListForAdmin', filters);
  // console.log("fjgfjshjfs", response)
  return response;
});



const courseSlice = createSlice({
  name: 'courses',
  initialState: {
    courses: [],
    total: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendingCourses.pending, (state) => {

        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrendingCourses.fulfilled, (state, action) => {
        // console.log("hello")
        state.loading = false;
        state.courses = action.payload.data; 

      })
      .addCase(fetchTrendingCourses.rejected, (state, action) => {

        state.loading = false;
        state.error = action.error.message;
      })


      .addCase(fetchAllCourses.pending, (state) => {

        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCourses.fulfilled, (state, action) => {
        // console.log("hello")
        state.loading = false;
        state.courses = action.payload.data.list;
        state.total = action.payload.data.total;

      })
      .addCase(fetchAllCourses.rejected, (state, action) => {

        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default courseSlice.reducer;
