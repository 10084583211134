import React, { useEffect } from "react";
import Card from "../../components/Card/Card"
import card1icon from "../../public/images/card1.svg"
import card2icon from "../../public/images/card2.svg"
import card3icon from "../../public/images/card3.svg"
import card4icon from "../../public/images/card4.svg"
import { useDispatch, useSelector } from "react-redux";
import { fetchTrendingCourses } from "../../Redux/courseSlice";
import './dashboard.css'
import TopCourseTable from "./Table/TopCourseTable";
import Chart from "./Chart/Chart";
import LatestStudentSignUpTable from "./Table/LatestStudentSignUpTable";



const Dashboard = () => {
  const dispatch = useDispatch();

  const selector = useSelector(state => state);


  const { courseSlice } = selector ? selector : {}
  console.log('courseSlicecourseSlice', courseSlice)
  let { error, loading } = courseSlice ? courseSlice : {}



  useEffect(() => {
    dispatch(fetchTrendingCourses({}));

  }, []);

  console.log("Selector: ", selector)
  console.log("courseSlice: ", courseSlice)
  console.log("error: ", error)
  console.log("loading: ", loading)


  const TbaleHeader = ['Project Name', 'Hours', 'Priority', 'memebers', 'progress'];
  const TbaleData = [
    {
      projectName: 'File Management App',
      hours: 34,
      priority: 'Medium',
      members: ['+5'],
      progress: 15,
      progressColor: 'bg-purple-500',
    },
    {
      projectName: 'Slack Team UI Design',
      hours: 34,
      priority: 'Medium',
      members: ['+5'],
      progress: 25,
      progressColor: 'bg-green-500',
    },
  ]

  const headers = ['Name', 'Role', 'Last Activity', ''];
  const data = [
    {
      avatar: 'https://via.placeholder.com/40',
      name: 'Jitu Chauhan',
      email: 'jitu@example.com',
      role: 'Learner',
      lastActivity: 'Today',
    },
    {
      avatar: 'https://via.placeholder.com/40',
      name: 'Sandeep Chauhan',
      email: 'sandeep@example.com',
      role: 'Expert',
      lastActivity: 'Today',
    },
  ];

  return (
    <>
      <section>
        <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE] p-10  shadow-lg  relative">
          <h2 className="text-2xl font-bold mb-4 text-white dahboardFont">All Stats</h2>
          <div className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-4  gap-4 absolute w-full mx-auto p-10 top-11 right-1">
            <Card title={'Total Learners'} content={'12'} img={card1icon} number={`180`} />
            <Card title={'Total Learners'} content={'55'} img={card2icon} number={`132`} />
            <Card title={'Total Learners'} content={'11'} img={card3icon} number={`12`} />
            <Card title={'Total Learners'} content={'5%'} img={card4icon} number={`$69`} />
          </div>
        </div>
      </section>
      <div className="mt-28 p-8">
        <TopCourseTable TbaleName='Top Course Performance' TbaleHeader={TbaleHeader} TbaleData={TbaleData} />
      </div>
      <div className="flex md:flex-row flex-col justify-between px-8 gap-4">
        <div className="bg-white shadow-sm p-2 rounded-md w-[40%]">
          <Chart />
        </div>
        <div className="bg-white shadow-sm  rounded-md  w-[60%]">
          <LatestStudentSignUpTable headers={headers} data={data} TbaleName={`Latest student sign-ups`} />
        </div>
      </div>


    </>
  );
};

export default Dashboard;
