
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../_helpers/apiConfig';

// Async thunk for fetching trending courses
// export const fetchTrendingCourses = createAsyncThunk("courses/trendingCourses", async (filters) => {
//   const response = await apiRequest('POST', '/v1/course/top-performing-courses', filters);
//   console.log("fjgfjshjfs",response)
//   return response; 
// });
export const expertManagement = createAsyncThunk("user/list", async (filters) => {
  const response = await apiRequest('POST', '/v1/user/list', filters);
  // console.log("UserList Dataaaaaaaaaaaaaaaa.................", response)
  return response;
});




const expertSlice = createSlice({
  name: 'experts',
  initialState: {
    experts: [],
    total: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(expertManagement.pending, (state) => {

        state.loading = true;
        state.error = null;
      })
      .addCase(expertManagement.fulfilled, (state, action) => {

        state.loading = false;
        state.experts = action.payload.data.list;
        state.total = action.payload.data.total;

      })
      .addCase(expertManagement.rejected, (state, action) => {

        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default expertSlice.reducer;
