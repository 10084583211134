import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineMenu } from 'react-icons/md'
import telgrosadmin from "../../public/images/telgorsAdminWhite.svg"
import dashboardlogo from "../../public/images/dashboard.svg"
import learnerroute from "../../public/images/learnerroute.svg"
import expertroute from "../../public/images/expertroute.svg"
import courseroute from "../../public/images/courseroute.svg"
import analyticsroute from "../../public/images/analyticsroute.svg"
import notificationroute from "../../public/images/notificationroute.svg"
import paymentroute from "../../public/images/paymentroute.svg"
import helpsupportroute from "../../public/images/helpsupportroute.svg"
import contentroute from "../../public/images/contentroute.svg"
import { RxDashboard } from "react-icons/rx";
import { LuUsers } from "react-icons/lu";
import { RiUserStarLine } from "react-icons/ri";
import { BsCardText } from "react-icons/bs";
import { TbTextScan2 } from "react-icons/tb";
import { PiChartBarHorizontal } from "react-icons/pi";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { TbCreditCardPay } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";








function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };



  return (
    <>
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          } absolute top-0 left-0 px-5 items-center justify-center   z-50 h-16`}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {/* <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"  ></path>
        </svg> */}
      </button>


      <div
        className={`${navbarOpen === false ? "hidden " : "flex "
          } fixed left-0 inset-y-0 w-64 z-50 flex-col bg-[#212B36]`}
      >
        <div className="flex flex-col w-64 relative flex-1 h-full max-h-full">

          <div className="cursor-pointer relative flex justify-between place-items-center flex-shrink-0 w-full z-50">
            <img src={telgrosadmin} alt="" className="h-[39px] mx-auto w-[185px] items-center md:ml-6 text-white m-4" />
            <div className="">
              {/* <MdOutlineMenu onClick={handleNavbar} className="text-white text-xl block mx-4" /> */}
            </div>
          </div>

          <nav className="overflow-y-auto  flex flex-col flex-wrap items-center justify-between relative w-64 z-10 h-full overflow-auto zilla-slab-medium ]">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1 bg-[#212B36] ">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="">
                  <span
                    onClick={() => onClickMenu("/app/dashboard")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 mb-4  ${location.pathname === "/app/dashboard"
                      ? "text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group ">
                      {/* <img src={dashboardlogo} /> */}
                      <RxDashboard size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap text-[14px]">Dashboard</span>
                  </span>

                  <span
                    onClick={() => onClickMenu("/app/learner")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/learner"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group ">
                    <LuUsers size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap">Learner Management</span>
                  </span>

                  <span
                    onClick={() => onClickMenu("/app/expert")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/expert"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group texthover">
                    <RiUserStarLine size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap">Expert Management</span>
                  </span>
                  {/* Course management */}
                  <span
                    onClick={() => onClickMenu("/app/course")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/course"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group texthover">
                    <BsCardText size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap">Course Management</span>
                  </span>
                  {/* Content management */}
                  <span
                    onClick={() => onClickMenu("/app/content")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/content"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group texthover">
                    <TbTextScan2 size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap">Content Management</span>
                  </span>
                  {/* Analytics and Reports */}
                  <span
                    onClick={() => onClickMenu("/app/analytics")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/analytics"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group texthover">
                    <PiChartBarHorizontal size={16} className="rotate-[-90deg]"/>
                    </span>
                    <span className="text-base  whitespace-nowrap">Analytics and Reports</span>
                  </span>

                  {/* Notifications */}
                  <span
                    onClick={() => onClickMenu("/app/notifications")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/notifications"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group texthover">
                    <MdOutlineNotificationsActive size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap">Notifications</span>
                  </span>

                  {/* Payments & Transactions */}
                  <span
                    onClick={() => onClickMenu("/app/payments")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/payments"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group texthover">
                    <TbCreditCardPay size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap"> Payments & Transactions </span>
                  </span>
                  {/* Help & Support */}

                  <span
                    onClick={() => onClickMenu("/app/helpsupport")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-4   ${location.pathname === "/app/helpsupport"
                      ? " text-white bg-[#37404a] "
                      : "text-[#919EAB]"
                      }`}
                  >
                    <span className="group texthover">
                    <BiSupport size={16}/>

                    </span>
                    <span className="text-base  whitespace-nowrap"> Help & Support </span>
                  </span>




                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
