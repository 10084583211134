import React from 'react'
import { TbVariable } from 'react-icons/tb'

const TopCourseTable = ({ TbaleHeader, TbaleName, TbaleData }) => {
    return (
        <div className=" bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold inter500 p-4 ">{TbaleName}</h2>
            <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                    <thead className='bg-[#F1F5F9] '>
                        <tr>
                            {
                                TbaleHeader.map((heading) => (
                                    <>
                                        <td className='py-2.5 px-4 capitalize inter500'>{heading}</td>
                                    </>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {TbaleData.map((data, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="py-2 px-4">{data.projectName}</td>
                                <td className="py-2 px-4 text-[#475569]">{data.hours}</td>
                                <td className="py-2 px-4">
                                    <span
                                        className={`px-3 py-1 text-sm rounded inter600 ${data.priority === 'High' ? 'bg-red-100 text-red-600' : 'bg-[#F59E0B] text-black'
                                            }`}
                                    >
                                        {data.priority}
                                    </span>
                                </td>
                                <td className="py-2 px-4 flex items-center space-x-2">
                                    <div className="flex -space-x-2">
                                        {data.members.map((member, idx) => (
                                            <div
                                                key={idx}
                                                className="w-6 h-6 rounded-full bg-blue-500 text-white text-xs flex items-center justify-center"
                                            >
                                                {member}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className="py-2 px-4">
                                    <div className="w-full h-2 bg-gray-200 rounded">
                                        <div
                                            className={`h-2 ${data.progressColor} rounded`}
                                            style={{ width: `${data.progress}%` }}
                                        ></div>
                                    </div>
                                    <span className="text-sm text-gray-500">{data.progress}%</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='flex justify-center place-items-center py-3 border-t'>
                    <p className='text-[#624BFF]'>View All Course</p>
                </div>
            </div>
        </div >
    )
}

export default TopCourseTable